<template>
    <div class="c-SectionFour u-relative u-fit-w" v-bind:class="{ 'is-page-ready': isPageReady, 'is-from-sections': isFromSections, 'is-from-section': isFromSection }">

        <sub-header v-bind:class="{ 'is-blurred': contentOverlayOpen && !isFF, 'is-hidden': contentOverlayOpen && isFF }" class="c-SectionFour-blur" v-bind:active="isPageReady" v-bind:number="4" v-bind:title="'Protectionism Continues'"></sub-header>

        <section class="js-section js-ui-switch js-nav-section c-SectionFour-intro u-relative u-fit-w u-viewport-fit-h u-color--white u-overflow-h" v-bind:style="{ height: minHeight +'px' }">
            <parallax-object v-bind:active="isPageReady" v-bind:ratio="-0.8" class="c-Container u-absolute u-pos-tl u-fit u-flex u-align-items-c | u-align-items-fe@sm">
                <object-fit v-bind:width="1280" v-bind:height="720" v-bind:width-mobile="374" v-bind:height-mobile="664" class="c-SectionFour-intro-video u-bg--blue u-fixed u-pos-tl u-fit">
                    <video-observer class="u-absolute u-pos-tl u-fit">
                        <video-responsive>
                            <video class="js-object-fit js-video u-absolute u-pos-tl u-fit" v-bind:poster="isMobile ? `${publicPath}static/images/four/poster-intro-mobile.jpg` : `${publicPath}static/images/four/poster-intro.jpg`" autoplay loop muted playsinline>
                                <source :src="`${publicPath}static/videos/section4.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/section4.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/section4-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/section4-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </object-fit>

                <div class="u-relative u-w10of16 u-offset-l-w3of16 u-force-3d | u-w12of16@md u-offset-l-w2of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-SectionFour-intro-title t-h1 u-w7of10 u-offset-l-w3of10 | u-fit-w@sm u-marg-l-0@sm">
                        <span class="c-SectionFour-line c-SectionFour-line--0 c-SectionFour-intro-title-line-1 u-block">Protectionism</span>
                        <span class="c-SectionFour-line c-SectionFour-line--1 c-SectionFour-intro-title-line-2 u-hide | u-block@sm">Continues</span>
                        <span class="c-SectionFour-line c-SectionFour-line--2 c-SectionFour-intro-title-line-3 u-hide u-offset-l-w5of16 | u-block@sm">to Grow</span>
                    </h1>

                    <div class="c-SectionFour-intro-foot u-relative u-flex u-align-items-fe u-fit-w">
                        <div class="u-marg-r-lg | u-hide@sm">
                            <span class="c-SectionFour-line c-SectionFour-line--2 c-SectionFour-intro-title-line-2 t-h1 u-block">Continues</span>
                        </div>

                        <div class="c-SectionFour-intro-desc u-w3of10  | u-w4of10@lg | u-w6of8@sm u-offset-l-w1of8@sm">
                            <div class="c-SectionFour-line c-SectionFour-line--2 | c-SectionFour-line-mobile--3">
                                <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                    <svg class="c-SectionFour-intro-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                        <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                        <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                        <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                    </svg>
                                    <span>section 04</span>
                                </span>
                            </div>

                            <p class="c-SectionFour-line c-SectionFour-line--3 c-SectionFour-intro-text t-text--sm | c-SectionFour-line-mobile--4">Despite a desire to collaborate and partner more, executives believe that protectionist policies can have a net-positive effect on innovation.</p>
                        </div>
                    </div>
                    <div class="u-w5of10 u-offset-l-w2of10 | u-hide@sm">
                        <span class="c-SectionFour-line c-SectionFour-line--4 c-SectionFour-intro-title-line-3 t-h1 u-block">to Grow</span>
                    </div>
                </div>
                <div class="c-SectionFour-intro-scroll c-Container u-absolute u-pos-bl u-fit-w">
                    <cursor-trigger v-bind:name="'scroll'" class="u-inline-block u-offset-l-w2of16 ">
                        <button v-on:click="onScroll" type="button" class="c-SectionFour-intro-scroll-btn c-ScrollBtn t-btn t-text--xxs u-font-bold">SCROLL</button>
                    </cursor-trigger>
                </div>
            </parallax-object>
        </section>

        <section v-bind:class="{ 'is-blurred': contentOverlayOpen && !isFF, 'is-hidden': contentOverlayOpen && isFF }" class="js-section js-ui-switch js-nav-section c-SectionFour-blur c-SectionFour-dataOne c-Container u-relative u-bg--white u-overflow-h">
            <h2 class="t-h3 u-w5of16 u-offset-l-w6of16 | u-w6of16@md u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">Innovation in a protectionist environment</h2>

            <p class="c-SectionFour-dataOne-desc t-h4 u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">Building on a trend from the last data gathered in the GEIB 2018 report, when protectionism first was detected entering the boardroom.</p>

            <cursor-trigger v-bind:name="'drag'" v-bind:active="!hasInteractedGraph"  v-bind:class="{ 'is-cursor-drag': !hasInteractedGraph }" class="c-SectionFour-dataOne-graphs u-relative u-fit-w">
                <appear-object v-bind:active="isPageReady">
                    <div v-on="graphHandlers">
                        <default-slider class="u-select-none">
                            <div class="u-w12of16 u-offset-l-w4of16 u-white-space-nowrap u-vacuum | u-w15of16@sm u-offset-l-w1of16@sm">
                                <div v-for="(item, i) in graph" v-bind:key="i" class="js-item c-SectionFour-dataOne-bar u-inline-block u-w1of12">
                                    <div class="c-SectionFour-dataOne-bar-inner">
                                        <cursor-trigger v-bind:active="hasInteractedGraph && Number(range) === 1 && item.cursorLabel !== ''" v-bind:name="'graph'" v-bind:value="Number(range) === 1 ? item.cursorLabel : ''" class="c-SectionFour-dataOne-bar-color" v-bind:style="{ height: (Number(range) === 1 ? item.value : item.oldValue) / graphMax * 100 + '%' }">
                                            <div class="c-SectionFour-dataOne-bar-color-bg u-absolute u-pos-tl u-fit" v-bind:class="'is-' + (Number(range) === 1 ? item.color : item.oldColor)"></div>
                                            <p class="u-relative t-text--sm u-text-center u-color--white u-pad-a-sm">{{ (Number(range) === 1 ? item.value : item.oldValue) }}%</p>
                                        </cursor-trigger>
                                        <div v-if="Number(range) === 1 && item.cursorLabel !== ''" class="c-SectionFour-dataOne-bar-diff has-drop-shadow u-absolute u-pos-bl u-hide u-align-items-c u-justify-content-c u-bg--white | u-flex@sm">
                                            <span class="t-text--sm u-color--black u-font-bold">{{item.cursorLabel}}</span>
                                        </div>
                                    </div>
                                    <p class="c-SectionFour-dataOne-bar-label t-text--xxs u-font-bold u-uppercase u-text-center">{{item.label}}</p>
                                </div>
                            </div>
                        </default-slider>
                    </div>
                </appear-object>

                <div v-bind:class="{ 'is-touch': isTouch }" class="c-Cursor-drag u-absolute u-hide u-align-items-c u-justify-content-c | u-flex@sm">
                    <span class="c-Cursor-drag-label u-relative t-text--sm u-color--black">Drag</span>
                    <div class="c-Cursor-drag-icon u-absolute u-flex u-align-items-c u-justify-content-sb">
                        <span class="c-Cursor-drag-icon-arrow">
                            <span class="c-Cursor-drag-icon-arrow-line"></span>
                        </span>
                        <span class="c-Cursor-drag-icon-arrow">
                            <span class="c-Cursor-drag-icon-arrow-line"></span>
                        </span>
                    </div>
                </div>
            </cursor-trigger>

            <div class="u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w2of16 | u-w4of16@lg | u-w5of16@md u-offset-l-w1of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                    <p class="c-Switch-legend t-text--sm u-opacity--50 u-italic">Select a year and drag the graph</p>
                </div>
                <div class="c-Switch u-w7of16 u-offset-l-w1of16 | u-w8of16@md | u-w6of8@sm u-offset-l-w1of8@sm u-marg-t-md@sm u-marg-b-lg@sm">
                    <ul class="t-list u-flex">
                        <li><button type="button" v-on:click="range = 0" v-bind:class="{ 'is-active': range === 0 }" class="c-Switch-btn c-Switch-btn--alt t-btn t-text--sm u-uppercase u-font-bold u-marg-r-md">2018</button></li>
                        <li><button type="button" v-on:click="range = 1" v-bind:class="{ 'is-active': range === 1 }" class="c-Switch-btn c-Switch-btn--alt t-btn t-text--sm u-uppercase u-font-bold u-marg-r-md">2020</button></li>
                    </ul>
                </div>
            </div>

            <div class="u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w2of16 | u-w4of16@lg | u-w5of16@md u-offset-l-w1of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                    <p class="t-text--sm u-marg-b-sm">% Who say a political protectionist stance on innovation in their country would be beneficial to business.</p>
                    <p v-if="range === 1" class="t-text--sm u-marg-t-md">+  -  Change, Jan 2020 to Sept 2020</p>

                    <div class="u-relative@sm u-marg-t-sm">
                        <question-btn number="11" question="Out of the statements below related to protectionism, which do you agree with the most?" response="[TRACKED BE] Base: Business executives Jan 2020: 2,307; Business executives Sept 2020: 1,128" />
                        <share-btn class="u-marg-l-xs" />
                    </div>
                </div>
                <div class="u-w7of16 u-offset-l-w1of16 | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <p class="c-SectionFour-dataOne-desc--alt t-h4">The majority still believe protectionism is good for business in most countries. However, this sentiment has weakened.</p>
                    <p class="c-SectionFour-dataOne-desc-paragraph t-text--sm">Discover more about how the protectionist stance has evolved during the COVID-19 pandemic.</p>
                    <discover-btn text="Discover" />
                </div>
            </div>
        </section>

        <section v-bind:class="{ 'is-blurred': contentOverlayOpen && !isFF, 'is-hidden': contentOverlayOpen && isFF }" class="js-section js-nav-section c-SectionFour-blur c-SectionFour-dataTwo c-Container u-relative u-bg--white">
            <h2 class="t-h3 u-w5of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">Benefits of protectionism <span class="u-invisible">a</span></h2>

            <div class=" u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                <p class="c-SectionFour-dataTwo-desc t-h4">There is an increased urgency to shield the domestic economy and domestic jobs, which a protectionist stance could help achieve.</p>

                <!-- <p class="c-SectionFour-dataTwo-legend c-Switch-legend t-text--sm u-color--gray-dark u-italic | u-hide@sm">Spin the globe and click on the hotspots</p> -->
            </div>

            <appear-object v-bind:active="isPageReady" class="u-relative u-flex u-flex-flow-row-wrap">
                <parallax-object v-bind:ratio="0.1" class="c-SectionFour-dataTwo-img u-w6of16 u-offset-l-w8of16 u-absolute u-pos-bl | u-w13of16@sm u-offset-l-w2of16@sm">
                    <div class="c-SectionFour-dataTwo-img-inner t-b-radius-md">
                        <img :src="`${publicPath}static/images/four/1.jpg`" alt="" class="u-absolute u-pos-tl u-fit">
                    </div>
                </parallax-object>

                <div class="c-SectionFour-dataTwo-gradient u-w10of16 | u-fit-w@sm">
                    <cursor-trigger v-bind:name="'spin'" v-bind:active="!hasInteractedGlobe" v-bind:class="{ 'is-cursor-spin': !hasInteractedGlobe }" class="c-SectionFour-dataTwo-gradient-inner t-b-radius-lg">
                        <generalelectric-ib2020-globe v-on="globeHandlers" ref="globe" mode="hotspot" v-bind:zoom="width < 1024 ? 0.95 : 1.15" transparent :baseuri="publicPath" class="u-absolute u-pos-tl u-fit"></generalelectric-ib2020-globe>

                        <div v-bind:class="{ 'is-touch': isTouch }" class="c-Cursor-spin u-absolute u-hide u-align-items-c u-justify-content-c | u-flex@sm">
                            <span class="c-Cursor-spin-label u-relative t-text--sm u-color--black">Spin</span>
                            <div class="c-Cursor-spin-icon u-absolute u-pos-tl u-fit u-flex u-align-items-c u-justify-content-c">
                                <img class="c-Cursor-spin-icon-img u-block u-force-3d" :src="`${publicPath}static/images/icon-spin.png`" alt="" width="64" height="56">
                            </div>
                        </div>
                    </cursor-trigger>

                    <div class="u-hide | u-block@sm u-marg-t-lg@sm u-w6of8@sm u-offset-l-w1of8@sm">
                        <p class="c-SectionFour-dataTwo-legend c-Switch-legend t-text--sm u-opacity--50 u-italic"><span class="u-hide@sm">Click on the hotspots</span><span class="u-hide | u-block@sm">Tap on the hotspots and scroll to see the data</span></p>
                        <p class="t-text--sm u-marg-b-md">Move around the globe to see how each country’s view on protectionism stands.</p>

                        <div class="u-relative">
                            <question-btn number="12" question="Out of the reasons below, why do you think protectionism would have a beneficial effect on businesses in your country?" response="Base: Business Executives who support a politically protectionist stance on innovation 2020: 1,375)" />
                            <share-btn class="u-marg-l-xs" />
                        </div>
                    </div>
                </div>


                <div class="u-w3of16 u-offset-l-w1of16 | u-w4of16@lg | u-w5of16@md | u-w14of16@sm u-offset-r-w1of16@sm">
                    <p class="t-text--sm">Top 3 reasons for adopting a protectionist stance in the current economic climate:</p>

                    <div class="u-relative u-marg-t-md">
                        <div v-bind:class="{ 'is-active': currentHotspot === 0 }" class="c-SectionFour-dataTwo-hotspot u-absolute u-pos-tl">
                            <h3 class="t-h2 u-color--blue">USA</h3>

                            <ul class="c-SectionFour-dataTwo-lines t-list u-marg-t-sm">
                                <li>
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 95%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">1.  Helping address the urgent public health needs created by the pandemic<span class="u-color--blue u-marg-x-xs">95%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 95%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">2.  Helping address the major economic problems created by the pandemic<span class="u-color--blue u-marg-x-xs">95%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 95%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">3.  Protecting jobs<span class="u-color--blue u-marg-x-xs">95%</span></p>
                                </li>
                            </ul>
                        </div>
                        <div v-bind:class="{ 'is-active': currentHotspot === 1 }" class="c-SectionFour-dataTwo-hotspot">
                            <h3 class="t-h2 u-color--blue">Africa</h3>

                            <ul class="c-SectionFour-dataTwo-lines t-list u-marg-t-sm">
                                <li>
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 98%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">1. Helping address the urgent public health needs created by the pandemic<span class="u-color--blue u-marg-x-xs">98%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 97%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">2. Protecting jobs<span class="u-color--blue u-marg-x-xs">97%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 96%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">3. Helping the domestic economy recover<span class="u-color--blue u-marg-x-xs">96%</span></p>
                                </li>
                            </ul>
                        </div>
                        <div v-bind:class="{ 'is-active': currentHotspot === 2 }" class="c-SectionFour-dataTwo-hotspot u-absolute u-pos-tl">
                            <h3 class="t-h2 u-color--blue">Asia</h3>

                            <ul class="c-SectionFour-dataTwo-lines t-list u-marg-t-sm">
                                <li>
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 95%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">1. Helping address the major economic problems created by the pandemic<span class="u-color--blue u-marg-x-xs">95%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 95%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">2. Protecting jobs<span class="u-color--blue u-marg-x-xs">95%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 95%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">3. Helping the domestic economy recover<span class="u-color--blue u-marg-x-xs">95%</span></p>
                                </li>
                            </ul>
                        </div>
                        <div v-bind:class="{ 'is-active': currentHotspot === 3 }" class="c-SectionFour-dataTwo-hotspot u-absolute u-pos-tl">
                            <h3 class="t-h2 u-color--blue">Europe</h3>

                            <ul class="c-SectionFour-dataTwo-lines t-list u-marg-t-sm">
                                <li>
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 93%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">1. Helping address the major economic problems created by the pandemic<span class="u-color--blue u-marg-x-xs">93%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 93%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">2. Helping the domestic economy recover<span class="u-color--blue u-marg-x-xs">93%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 91%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">3. Protecting jobs<span class="u-color--blue u-marg-x-xs">91%</span></p>
                                </li>
                            </ul>
                        </div>
                        <div v-bind:class="{ 'is-active': currentHotspot === 4 }" class="c-SectionFour-dataTwo-hotspot u-absolute u-pos-tl">
                            <h3 class="t-h2 u-color--blue">LATAM</h3>

                            <ul class="c-SectionFour-dataTwo-lines t-list u-marg-t-sm">
                                <li>
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 97%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">1. Helping address the urgent public health needs created by the pandemic<span class="u-color--blue u-marg-x-xs">97%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 95%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">2. Protecting jobs<span class="u-color--blue u-marg-x-xs">95%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 93%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">3. Helping the domestic economy recover<span class="u-color--blue u-marg-x-xs">93%</span></p>
                                </li>
                            </ul>
                        </div>
                        <div v-bind:class="{ 'is-active': currentHotspot === 5 }" class="c-SectionFour-dataTwo-hotspot u-absolute u-pos-tl">
                            <h3 class="t-h2 u-color--blue">Middle East</h3>

                            <ul class="c-SectionFour-dataTwo-lines t-list u-marg-t-sm">
                                <li>
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 93%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">1. Protecting jobs<span class="u-color--blue u-marg-x-xs">93%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 93%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">2. Helping the top innovating companies to remain successful<span class="u-color--blue u-marg-x-xs">93%</span></p>
                                </li>
                                <li class="u-marg-t-xs">
                                    <div class="c-SectionFour-dataTwo-lines-item">
                                        <div class="c-SectionFour-dataTwo-lines-item-blue" style="width: 92%;"></div>
                                    </div>
                                    <p class="t-text--xxs u-uppercase u-font-bold">3. Maintaining the status as an innovation leader<span class="u-color--blue u-marg-x-xs">92%</span></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </appear-object>

            <div class="c-SectionFour-dataTwo-foot u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w2of16 | u-w4of16@lg | u-w5of16@md u-offset-l-w1of16@md | u-hide@sm">
                    <p class="c-SectionFour-dataTwo-legend c-Switch-legend t-text--sm u-color--gray-dark u-italic">Click on the hotspots</p>
                    <p class="t-text--sm u-marg-b-md">Move around the globe to see how each country’s view on protectionism stands.</p>
                    <div class="u-flex">
                        <question-btn number="50" question="Thinking about the reasons for adopting a protectionist stance, how important would you say the following are in the current economic climate?" response="[NEW SEPT 2020 BE] Business executives Sept 2020: 1,128" />
                        <share-btn class="u-marg-l-xs" />
                    </div>
                </div>

                <div class="u-w7of16 u-offset-l-w1of16 | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm | u-w14of16@sm u-offset-l-w1of16@sm">
                    <p class="c-SectionFour-dataTwo-desc c-SectionFour-dataTwo-desc--alt t-h4">COVID-19 has bought on the urgent need to protect the domestic economy. Job security has become a top concern, which business executives feel can be alleviated through protectionism.</p>
                </div>
            </div>

        </section>

        <section class="js-section js-nav-section c-SectionFour-dataThree c-Container u-relative u-bg--white u-overflow-h">
            <h2 class="t-h3 u-w5of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">The need to collaborate</h2>

            <p class="c-SectionFour-dataThree-desc t-h4 u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">This call for protectionist-inspired measures seems paradoxical to the open borders and collaboration executives want to experience through international partnerships with industries and countries.</p>

            <div class="c-SectionFour-dataThree-head u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w6of16 | u-offset-l-w5of16@lg | u-w11of16@sm u-offset-l-w1of16@sm">
                    <div ref="numberOne" class="c-SectionFour-dataThree-number u-color--blue">
                        <div v-bind:class="'is-number-'+numberOne" class="u-relative u-inline-block">
                            <span v-for="n in 20" v-bind:key="n" v-bind:class="n != 20 ? 'u-absolute u-pos-tl' : ''" class="c-SectionFour-dataThree-number-item">{{57-(20-n)}}<sup>%</sup></span>
                        </div>
                    </div>
                </div>
                <div class="u-w3of16 u-offset-l-w1of16 | u-w4of16@lg | u-w5of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <div class="u-w11of16@sm u-offset-l-w1of16@sm">
                        <p class="t-text--sm">Agree with the fact that trade restrictions and disputes make it more important for collaboration across industries due to less collaboration between countries.</p>
                        <p class="t-text--sm u-marg-t-sm">Agree with the fact that trade restrictions and disputes make company-to-company collaborations an integral part of the innovation process.</p>
                    </div>
                    <div class="u-marg-t-sm | u-w14of16@sm u-offset-l-w1of16@sm u-relative@sm u-marg-t-md@sm">
                        <question-btn v-bind:right="true" number="15" question="To what extent do you feel the following statements reflect something that has already happened…" response="(NET Happened / happening) Base: Business Executives stating trade disputes have a negative impact on innovation 2020: 1,441)" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

            <div class="u-relative u-fit-w">
                <div class="c-SectionFour-dataThree-video u-w10of16 u-offset-l-w5of16 | u-w12of16@md u-offset-l-w3of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <video-observer class="c-SectionFour-dataThree-video-container t-b-radius-lg">
                        <video-responsive>
                            <video class="js-video u-absolute u-pos-tl u-fit" :poster="`${publicPath}static/images/four/collaboration-poster.jpg`" preload="auto" loop muted playsinline>
                                <source :src="`${publicPath}static/videos/four/collaboration.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/four/collaboration.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/four/collaboration-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/four/collaboration-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </div>

                <parallax-object v-bind:ratio="0.1" class="c-SectionFour-dataThree-img u-w3of16 u-offset-l-w3of16 u-absolute u-pos-tl | u-offset-l-w1of16@md | u-w10of16@sm u-offset-l-w1of16@sm">
                    <div class="c-SectionFour-dataThree-img-inner t-b-radius-md">
                        <img :src="`${publicPath}static/images/four/2.jpg`" alt="" class="u-absolute u-pos-tl u-fit">
                    </div>
                </parallax-object>

                <parallax-object v-bind:ratio="0.2" class="c-SectionFour-dataThree-gradient u-w3of16 u-offset-l-w2of16 u-absolute u-pos-tl | u-offset-l-w0of16@md | u-w5of16@sm u-offset-l-w4of8@sm">
                    <div class="c-SectionFour-dataThree-gradient-inner t-b-radius-sm"></div>
                </parallax-object>
            </div>

            <div class="c-SectionFour-dataThree-foot u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w6of16 | u-offset-l-w5of16@lg | u-w11of16@sm u-offset-l-w1of16@sm">
                    <div ref="numberTwo" class="c-SectionFour-dataThree-number u-color--blue">
                        <div v-bind:class="'is-number-'+numberTwo" class="u-relative u-inline-block">
                            <span v-for="n in 20" v-bind:key="n" v-bind:class="n != 20 ? 'u-absolute u-pos-tl' : ''" class="c-SectionFour-dataThree-number-item">{{87-(20-n)}}<sup>%</sup></span>
                        </div>
                    </div>
                </div>
                <div class="u-w3of16 u-offset-l-w1of16 | u-w4of16@lg | u-w5of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <div class="u-w11of16@sm u-offset-l-w1of16@sm">
                        <p class="t-text--sm">Of Business Executives believe their business could be more successful through partnerships and collaboration.</p>
                    </div>
                    <div class="u-marg-t-sm | u-w14of16@sm u-offset-l-w1of16@sm u-marg-t-md@sm u-relative@sm">
                        <question-btn v-bind:right="true" number="17" question="How much do you agree or disagree with the following statements?" response="(NET Agree) Base: Business Executives 2020: 2,307)" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

        </section>

        <next-section v-bind:url="'/sections/jobs-and-artificial-intelligence'" v-bind:label="'Section 05'" v-bind:title="'Jobs and Artificial&nbsp;Intelligence'" />

        <content-overlay>
            <div class="c-SectionFour-dataFour">
                <p class="t-h4">COVID-19 is bringing out protectionist instincts, with 1 in 2 saying their government has taken a more protectionist stance on innovation over the last 6 months.</p>
                <div class="u-marg-t-sm u-relative | u-fit@sm u-marg-t-md@sm" style="z-index: 3;">
                    <question-btn number="47" question="Which of the following best describes your view on the protectionist stance your government has taken on innovation in your country over the last 6 months (during the COVID-19 pandemic)?" response="[NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                    <share-btn class="u-marg-l-xs" />
                </div>

                <div class="c-SectionFour-dataThree-cols u-marg-t-sm u-flex u-flex-flow-row-wrap u-justify-content-sb u-relative" style="z-index: 2;">
                    <div class="c-SectionFour-dataThree-col">
                        <div ref="numberThree" class="c-SectionFour-dataThree-number u-color--blue">
                            <div v-bind:class="'is-number-'+numberThree" class="u-relative u-inline-block">
                                <span v-for="n in 20" v-bind:key="n" v-bind:class="n != 20 ? 'u-absolute u-pos-tl' : ''" class="c-SectionFour-dataThree-number-item">{{69-(20-n)}}<sup>%</sup></span>
                            </div>
                        </div>
                    </div>
                    <div class="c-SectionFour-dataThree-col">
                        <div class="u-w11of16@sm u-offset-l-w1of16@sm">
                            <p class="t-text--sm">In instances where the government has increased its protectionist stance, 69% of Business Executives think this will have a positive impact on innovation.</p>
                        </div>
                        <div class="u-marg-t-sm | u-w14of16@sm u-offset-l-w1of16 u-relative@sm u-marg-t-md@sm">
                            <question-btn v-bind:right="true" number="48" question="You said that your country’s government has taken a more/the same/less protectionist stance in the last 6 months. To what extent has this had a positive or negative impact on innovation?" response="[NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128 [Those who believe their government has taken a more protectionist stance: 565; the same protectionist stance: 384; a less protectionist stance: 143]" />
                            <share-btn class="u-marg-l-xs" />
                        </div>
                    </div>
                </div>

                <p class="t-h4">Yet, few believe that this surge in protectionism will last long-term. Less than a quarter (22%) believe that this rise in protectionism in their markets will last more than 3 years.</p>
                <div class="u-marg-t-sm | u-fit@sm u-relative@sm u-marg-t-md@sm u-marg-b-xxl@sm">
                    <question-btn number="49" question="You said that your country’s government has taken a more protectionist stance in the last 6 months How long do you think these changes will last?" response="[NEW SEPT 2020 BE] Base: Business executives Sept 2020 who believe their government has taken a more protectionist stance: 565" />
                    <share-btn class="u-marg-l-xs" />
                </div>
            </div>
        </content-overlay>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { gsap, Quad} from 'gsap';
    import scrollToY from '@/utilities/scrollToY';
    import getOffsetTop from '@/utilities/getOffsetTop';

    import CursorTrigger from '@/components/CursorTrigger';
    import ParallaxObject from '@/components/ParallaxObject';
    import ObjectFit from '@/components/ObjectFit';
    import VideoObserver from '@/components/VideoObserver';
    import NextSection from '@/components/NextSection';
    import DefaultSlider from '@/components/DefaultSlider';
    import AppearObject from '@/components/AppearObject';
    import SubHeader from '@/components/SubHeader';
    import QuestionBtn from '@/components/QuestionBtn';
    import VideoResponsive from '@/components/VideoResponsive';
    import ShareBtn from '@/components/ShareBtn';
    import DiscoverBtn from '@/components/DiscoverBtn';
    import ContentOverlay from '@/components/ContentOverlay';

    export default {
        name: 'Home',

        components: {
            CursorTrigger,
            ParallaxObject,
            ObjectFit,
            VideoObserver,
            NextSection,
            DefaultSlider,
            AppearObject,
            SubHeader,
            QuestionBtn,
            VideoResponsive,
            ShareBtn,
            DiscoverBtn,
            ContentOverlay,
        },

        props: {
            initIndex: { type: Number, default: 0 },
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                isPageReady: false,
                isFromSections: false,
                isFromSection: false,

                graphHandlers: {
                    pointerdown: this.setInteractiveGraph,
                    touchstart: this.setInteractiveGraph,
                },

                globeHandlers: {
                    pointerdown: this.setInteractiveGlobe,
                    touchstart: this.setInteractiveGlobe,
                },

                hasInteractedGraph: false,
                hasInteractedGlobe: false,

                range: 1,

                numberOne: 0,
                numberTwo: 0,
                numberThree: 0,

                graphMax: 75,
                graph: [
                    {
                        value: 75,
                        oldValue: 71,
                        label: 'UAE',
                        cursorLabel:  '+14',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 72,
                        oldValue: 61,
                        label: 'Indonesia',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 64,
                        oldValue: 64,
                        label: 'Iraq',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 64,
                        oldValue: 56,
                        label: 'Turkey',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 63,
                        oldValue: 63,
                        label: 'India',
                        cursorLabel:  '-14',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 63,
                        oldValue: 67,
                        label: 'Vietnam',
                        cursorLabel:  '-4',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 62,
                        oldValue: 62,
                        label: 'Saudi Arabia',
                        cursorLabel:  '-6',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 61,
                        oldValue: 52,
                        label: 'USA',
                        cursorLabel:  '+4',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 61,
                        oldValue: 58,
                        label: 'UK',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 61,
                        oldValue: 57,
                        label: 'Mexico',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 58,
                        oldValue: 51,
                        label: 'Nigeria',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 56,
                        oldValue: 39,
                        label: 'South Africa',
                        cursorLabel:  '-5',
                        color: 'blue',
                        oldColor: 'cyan',
                    },
                    {
                        value: 55,
                        oldValue: 51,
                        label: 'China',
                        cursorLabel:  '-12',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 54,
                        oldValue: 56,
                        label: 'Total',
                        cursorLabel:  '',
                        color: 'gray',
                        oldColor: 'gray',
                    },
                    {
                        value: 54,
                        oldValue: 63,
                        label: 'France',
                        cursorLabel:  '-4',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 53,
                        oldValue: 60,
                        label: 'Malaysia',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 53,
                        oldValue: 49,
                        label: 'Germany',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'cyan',
                    },
                    {
                        value: 52,
                        oldValue: 53,
                        label: 'Japan',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 50,
                        oldValue: 50,
                        label: 'Israel',
                        cursorLabel:  '',
                        color: 'blue',
                        oldColor: 'blue',
                    },
                    {
                        value: 49,
                        oldValue: 49,
                        label: 'Kenya',
                        cursorLabel:  '',
                        color: 'cyan',
                        oldColor: 'cyan',
                    },
                    {
                        value: 48,
                        oldValue: 42,
                        label: 'Poland',
                        cursorLabel:  '-5',
                        color: 'cyan',
                        oldColor: 'cyan',
                    },
                    {
                        value: 44,
                        oldValue: 47,
                        label: 'South Korea',
                        cursorLabel:  '',
                        color: 'cyan',
                        oldColor: 'cyan',
                    },
                    {
                        value: 13,
                        oldValue: 51,
                        label: 'Brazil',
                        cursorLabel:  '-45',
                        color: 'cyan',
                        oldColor: 'blue',
                    }
                ],

                currentHotspot: 0,
            };
        },

        computed: {
            ...mapState('window', [
                'width',
                'minHeight',
                'isFF',
                'isTouch',
            ]),

            ...mapState('main', [
                'contentOverlayOpen',
            ]),

            ...mapGetters('window', [
                'halfHeight',
                'isMobile',
            ]),
        },

        watch: {
            contentOverlayOpen: 'onContentOverlayOpenToggle',
        },

        beforeRouteEnter (to, from, next) {
            next((vm) => {
                if(from.meta.type === 'section') {
                    vm.isFromSection = true;
                }

                if (from.name === 'Sections') {
                    vm.isFromSections = true;
                }
            });
        },

        created() {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.onNumberAppaer(entry.target);
                        this.observer.unobserve(entry.target);
                    }
                });
            });

            this._onHotspotSelected = this.onHotspotSelected.bind(this);
        },

        mounted() {
            this.setCurrentNav(40);
            this.$eventHub.$on('page:ready', this.onPageReady);

            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);
            this.$eventHub.$on('route:scroll', this.onRouteScroll);

            this.$nextTick(() => {
                this.$eventHub.$emit('page:enter');

                this.$navSections = Array.from(this.$el.querySelectorAll('.js-nav-section'));
            });

            this.observer.observe(this.$refs.numberOne);
            this.observer.observe(this.$refs.numberTwo);

            this.globeData = {
                "USA": {
                    latitude: 39.999733,
                    longitude: -98.678503,
                },
                "Africa": {
                    latitude: 2.203125,
                    longitude: 19.010978,
                },
                "Asia": {
                    latitude: 26.205539,
                    longitude: 92.289873,
                },
                "Europe": {
                    latitude: 48.122632,
                    longitude: 14.604547,
                },
                "LATAM": {
                    latitude: -8.169778,
                    longitude: -56.763174,
                },
                "Middle East": {
                    latitude: 27.615443,
                    longitude: 41.770975,
                },
            };

            this.$refs.globe.data = this.globeData;
            this.$refs.globe.addEventListener('select', this._onHotspotSelected);
        },

        beforeDestroy() {
            this.$eventHub.$off('page:ready', this.onPageReady);

            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('route:scroll', this.onRouteScroll);

            this.observer.unobserve(this.$refs.numberOne);
            this.observer.unobserve(this.$refs.numberTwo);

            this.$refs.globe.removeEventListener('select', this._onHotspotSelected);
        },

        methods: {
            ...mapActions('main', [
                'setCurrentNav',
                'toggleContentOverlayOpen'
            ]),

            resize() {
                if (!this.$navSections) return;

                this.cuePoints = [];

                this.$navSections.forEach((el) => {
                    this.cuePoints.push(getOffsetTop(el));
                });
            },

            update() {
                if (!this.cuePoints) return;

                this.cuePoints.forEach((offset, i) => {
                    let nextCuePoints = this.cuePoints[i+1] ? this.cuePoints[i+1] : 99999999;

                    if (this.$root.smoothScroll > this.cuePoints[i] - this.halfHeight && this.$root.smoothScroll < nextCuePoints - this.halfHeight ) {
                        this.setCurrentNav(40+i);
                    }
                });
            },

            onScroll() {
                scrollToY(this.$root.scrollTop + this.minHeight);
            },

            onPageReady() {
                this.isPageReady = true;

                if (this.initIndex) {
                    this.cuePoints[this.initIndex];

                    const offset = this.initIndex < 2 ? 0 : 160;
                    scrollToY(this.cuePoints[this.initIndex] - offset);
                }

                setTimeout(() => {
                    this.$eventHub.$emit('webgl:pause');
                }, 800);
            },

            onRouteScroll(index) {
                if (!this.isPageReady) return;

                const offset = index < 2 ? 0 : 160;
                scrollToY(this.cuePoints[index] - offset);
            },

            onNumberAppaer(elem) {
                let init  = { value: 0 };
                // let target = (elem === this.$refs.numberOne) ? 57 : 87;
                let target = 20;

                gsap.to(init, 0.8, { value: target, ease: Quad.easeOut, onUpdate: () => {
                    if (elem === this.$refs.numberOne) {
                        this.numberOne = Math.round(init.value);
                    } else {
                        this.numberTwo = Math.round(init.value);
                    }
                } });
            },

            onContentOverlayOpenToggle() {
                let init  = { value: 0 };
                let target = 20;

                if (this.contentOverlayOpen) {
                    setTimeout(() => {
                        gsap.to(init, 0.8, { value: target, ease: Quad.easeOut, onUpdate: () => {
                            this.numberThree = Math.round(init.value);
                        } });
                    }, 600);
                }

                if (!this.contentOverlayOpen) {
                    setTimeout(() => this.numberThree = init.value, 800);
                }
            },

            onHotspotSelected(event) {
                Object.keys(this.globeData).forEach((name, i) => {
                    if (name === event.detail.id) {
                        this.currentHotspot = i;
                    }
                })
            },

            setInteractiveGraph() {
                this.hasInteractedGraph = true;
            },

            setInteractiveGlobe() {
                this.hasInteractedGlobe = true;
            },
        }
    }
</script>

<style lang="stylus">
    .c-SectionFour {
        &-blur {
            transition filter 0.5s $ease-out-quart 0.4s, background 0.5s $ease-out-quart 0.4s, opacity 0.5s $ease-out-quart 0.4s
            transform translate3d(0, 0, 0)
            opacity 1

            &.is-blurred {
                filter blur(60px)
                background #fafcfe

                @media $breakpoints.sm {
                    filter unset
                }
            }

            &.is-hidden {
                background #cacaca
                opacity 0.1
            }
        }

        &-line {
            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity

            .is-page-ready & {
                opacity 1
                transform translateZ(0)
            }

            for n in 0..4 {
                &--{n} {
                     transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)
                }
            }

            .is-from-sections & {
                opacity 1
                transform translateZ(0)
                transition none;
            }
        }

        &-line-mobile {
            @media $breakpoints.sm {
                for n in 0..5 {
                    &--{n} {
                         transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)
                    }
                }
            }
        }

        &-intro {
            z-index 0

            &-video {
                opacity 0
                transform translateZ(0)
                transition opacity 0.8s $ease-out-quart
                will-change opacity

                .is-page-ready & {
                    opacity 1
                }

                .is-from-section & {
                    opacity 1
                    transition none
                }
            }

            &-title {
                &-line-2 {
                    top: 0.15em;
                    position: relative;

                    @media $breakpoints.sm {
                        top 0
                        margin-top calc(60 * 100vh / 1125)
                    }
                }
                &-line-3 {
                    margin-top 15px

                    @media $breakpoints.sm {
                        margin-top 0
                    }
                }
            }

            &-foot {
                margin-top 80px

                @media $breakpoints.lg {
                    margin-top calc(80 * 100vh / 1125)
                }

                @media $breakpoints.sm {
                    margin-top 0
                }
            }

            &-desc {
                margin-top 25px

                @media $breakpoints.sm {
                    margin-top 75px
                    margin-bottom 95px
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            &-icon {
                margin-top -4px
                margin-right 12px
            }

            &-text {
                margin-top 20px
            }

            &-scroll {
                margin-bottom 90px

                @media $breakpoints.sm {
                    margin-bottom 42px
                }

                &-btn {
                    opacity 0
                    transform translateZ(0)
                    transition opacity 0.8s $ease-out-quart
                    will-change opacity

                    .is-page-ready & {
                        opacity 1
                    }
                }
            }
        }

        &-dataOne {
            z-index 1
            padding-top 355px
            padding-bottom 310px

            @media $breakpoints.sm {
                padding-top 270px
                padding-bottom 270px
            }

            &-desc {
                padding-top 145px
                padding-bottom 110px

                &--alt {
                    padding-top 125px

                    @media $breakpoints.sm {
                        padding-top 110px
                    }
                }

                &-paragraph {
                    margin-top 55px
                    width 50%

                    @media $breakpoints.md {
                        margin-top 50px
                        width 90%
                    }
                }
            }


            // &-range {
            //     appearance: none;
            //     height: 1px
            //     border-radius: 5px;
            //     background: $colors['blue'];
            //     outline: none;
            //     padding: 0;
            //     margin: 0 20px;
            //     cursor: pointer;

            //     // Range Handle
            //     &::-webkit-slider-thumb {
            //         appearance: none;
            //         width: 22px;
            //         height: 22px;
            //         border-radius: 50%;
            //         background: $colors['blue'];
            //         cursor: pointer;
            //         transition: transform 0.6s $ease-out-quart
            //         will-change transform

            //         &:hover {
            //             transform: scale(1.2) translateZ(0);
            //         }
            //     }

            //     &::-moz-range-thumb {
            //         appearance: none;
            //         width: 22px;
            //         height: 22px;
            //         border-radius: 50%;
            //         background: $colors['blue'];
            //         cursor: pointer;
            //         transition: transform 0.6s $ease-out-quart
            //         will-change transform

            //         &:hover {
            //             transform: scale(1.2) translateZ(0);
            //         }
            //     }
            // }

            &-graphs {
                margin-top 40px
                margin-bottom 80px

                @media $breakpoints.sm {
                    margin-bottom 75px
                }
            }

            &-bar {
                transform translateZ(0)
                will-change transform
                overflow hidden
                position relative
                padding 0 3px
                min-width 80px

                @media $breakpoints.sm {
                    min-width 75px
                    // height 450px
                }

                for n in (1..13) {
                    &:nth-child({n}) {
                        .c-SectionFour-dataOne-bar-color-bg {
                            .is-appear & {
                                transition transform 0.8s $ease-out-quart (n * 0.05s)
                            }
                        }
                    }
                }

                &-inner {
                    height 400px
                    width 100%
                    position relative
                    display inline-block
                    vertical-align bottom
                }

                &-color {
                    position absolute
                    bottom 0
                    left 0
                    width 100%
                    height 100%

                    transition height 0.4s $ease-out-quart
                    will-change height
                    transform translateZ(0)

                    &-bg {
                        border-radius 4px
                        box-shadow: inset 0px 1px 0px 1px rgba(white, 0.5)
                        transform-origin bottom
                        transform scaleY(0) translateZ(0)
                        transition transform 0s $ease-out-quart 0.4s
                        will-change transform

                        // background linear-gradient(to bottom, #0060b4, #86daef, #FFFFFF);

                        &.is-blue {
                            background linear-gradient(to bottom, #0060b4, #0ea2df, #FFFFFF);
                        }

                        &.is-dark {
                            background linear-gradient(to bottom, #12375b, #0060b4, #FFFFFF);
                        }

                        &.is-cyan {
                            background linear-gradient(to bottom, #0eb5df, #86daef, #FFFFFF);
                        }

                        &.is-gray {
                            background linear-gradient(to bottom, #c0c0c0, #FFFFFF);
                        }

                        .is-appear & {
                            transform scaleY(1) translateZ(0)
                        }
                    }

                    .u-pad-a-sm {
                        transform translateZ(0)
                        will-change transform
                    }

                    .t-text--xxs {
                        letter-spacing 0.05em
                    }
                }

                &-label {
                    margin-top 40px
                    letter-spacing 0.05em
                }

                &-diff {
                    width 42px
                    height 42px

                    border-radius 50%
                    box-shadow: 0px 10px 24px -11px rgba(0, 0, 0, 0.75);

                    left 50%
                    margin-left -21px

                    @media $breakpoints.sm {
                        box-shadow none

                        &.has-drop-shadow {
                            box-shadow: 0px 15px 35px -12px rgba(0, 0, 0, 0.4);
                        }
                    }
                }
            }

            &-legend {
                width 10px
                height 8px
                border-radius 2px
                margin-right 10px
                display inline-block
                vertical-align middle
            }
        }

        &-dataTwo {
            z-index 1
            padding-bottom 200px

            @media $breakpoints.sm {
                padding-bottom 160px
            }

            &-desc {
                padding-top 145px
                padding-bottom 155px

                @media $breakpoints.sm {
                    padding-bottom 115px
                }


                &--alt {
                    padding-top 250px

                    @media $breakpoints.sm {
                        padding-top 135px
                    }
                }
            }

            &-legend {
                // margin-bottom 40px
            }

            &-gradient {
                padding-right 2.778%

                @media $breakpoints.sm {
                    padding-right 0
                    padding-top 110px
                    margin-bottom 75px
                }

                &-inner {
                    aspect-ratio(808, 504)
                    overflow hidden

                    backdrop-filter: blur(7px);
                    background linear-gradient(to bottom, #0060b4, rgba(#0eb5df, 0.1));

                    @media $breakpoints.md {
                        aspect-ratio(1, 1.2)
                    }

                    @media $breakpoints.sm {
                        aspect-ratio(678, 886)
                    }
                }
            }

            &-img {
                padding-right 4.44%

                bottom -100px

                @media $breakpoints.lg {
                    bottom -150px
                }

                @media $breakpoints.md {
                    bottom -60px
                }

                @media $breakpoints.sm {
                    padding 0
                    bottom auto
                    top 0
                }

                &-inner {
                    aspect-ratio(446, 288)
                    overflow hidden
                }
            }

            &-hotspot {
                opacity 0
                visibility hidden
                transform translateZ(0)
                will-change opacity, visibility

                transition none

                &.is-active {
                    opacity 1
                    visibility visible

                    transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
                }
            }

            &-lines {
                li {
                    margin-bottom 5px
                }

                .t-text--xxs {
                    letter-spacing 0.05em
                }

                &-item {
                    overflow hidden
                    position relative
                    height 4px
                    width 100%
                    background $colors['gray'];
                    margin-bottom 5px

                    &-blue, &-cyan, &-gray {
                        position absolute
                        top 0
                        left 0
                        width 100%
                        height 100%

                        will-change transform
                        transform-origin left
                        transform scaleX(0) translateZ(0)
                        transition transform 0.8s $ease-out-quart

                        .is-appear & {
                            transform scaleX(1) translateZ(0)
                        }
                    }

                    &-blue {
                        background $colors['blue'];
                    }

                    &-cyan {
                        background $colors['blue-light'];
                    }

                    &-gray {
                        background $colors['gray'];
                    }
                }
            }

            &-foot {
                margin-top 60px

                @media $breakpoints.sm {
                    margin-top 0
                }
            }
        }

        &-dataThree {
            padding-bottom 285px

            @media $breakpoints.sm {
                padding-bottom 280px
            }

            &-desc {
                padding-top 145px
                padding-bottom 145px

                @media $breakpoints.sm {
                    padding-bottom 120px
                }
            }

            &-number {
                line-height 1
                font-size 11.528vw

                @media $breakpoints.sm {
                    font-size 40.8vw
                }

                sup {
                    top -0.5em
                    font-size 60%
                }

                &-item {
                    opacity 0
                    will-change opacity
                    transform translateZ(0)

                    for n in (0..20) {
                        &:nth-child({n}) {
                            .is-number-{n} & {
                                opacity 1
                            }
                        }
                    }
                }
            }

            &-head {
                padding-bottom 120px

                @media $breakpoints.sm {
                    padding-bottom 110px
                }
            }

            &-cols {
                margin 85px 0

                @media $breakpoints.sm {
                    display block
                    margin 105px 0 115px
                }
            }

            &-col {
                width calc(50% - 25px)

                @media $breakpoints.lg {
                    &:first-child {
                        width calc(40% - 25px)
                    }

                    &:nth-child(2) {
                        width calc(60% - 25px)
                    }
                }

                @media $breakpoints.sm {
                    width 100% !important
                }
            }

            &-video {
                padding-right 2.78%

                @media $breakpoints.sm {
                    padding-right 0
                    padding-bottom 210px
                }

                &-container {
                    aspect-ratio(16, 9)
                    overflow hidden
                    // background $colors['black'];

                    @media $breakpoints.sm {
                        aspect-ratio(374, 664)
                    }
                }
            }

            &-img {
                top 55px

                @media $breakpoints.sm {
                    top auto
                    bottom 0
                }

                &-inner {
                    aspect-ratio(254, 330)
                    overflow hidden
                }
            }

            &-gradient {
                top 30px
                // padding 0 2.78%
                // width 18.75%;
                width 13.19%
                margin-left: 15.28%;
                backdrop-filter: blur(7px);

                @media $breakpoints.md {
                    padding 0
                    margin-left: 12.5%;
                    width 18.75%
                }

                @media $breakpoints.sm {
                    top auto
                    padding 0
                    bottom 95px
                    margin-left 50%
                    width 31.25%
                }

                &-inner {
                    aspect-ratio(170, 120)
                    overflow hidden

                    background linear-gradient(to top, #0060b4, rgba(#0eb5df, 0.1));
                }
            }

            &-foot {
                padding-top 115px

                @media $breakpoints.sm {
                    padding-top 90px
                }
            }
        }
    }
</style>